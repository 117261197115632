import React, { FC } from "react";
import * as styles from "./index.module.scss";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section, SubSection } from "~components/templates/section";
import { FaqStyle } from "~components/templates/faq-style";

export const AssessmentDetailQuestion: FC = () => {
  return (
    <>
      <Section background="subtle-gray">
        <SubSection>
          <Common2PaneSectionLayout>
            <FaqStyle>
              <h2>
                <span>セキュリティ診断について</span>
                <span>まだよくわからない方向けの</span>
                <span>よくある質問</span>
              </h2>
              <div>
                <h3>各種セキュリティ診断の料金の目安や概算を知りたいです。</h3>
                <p>
                  『診断費用を「自分で計算できる」資料』をご用意しています。
                  <a href="/assessment/price_doc/">ダウンロードフォーム</a>
                  より無料でダウンロード可能です。
                </p>
              </div>
              <div>
                <h3>
                  WebアプリケーションやAWSのセキュリティ診断を予定しています。予算内に収まるか不安なのですが、収まるようなセキュリティ診断のプランはありますか。
                </h3>
                <p>
                  はい、弊社ではお客様のご予算にあわせた提案を行っております。お見積り時に弊社エンジニアが頂いた資料等から判断し、予算内に収まる効果的なセキュリティ診断を提案しております。
                </p>
              </div>
              <div>
                <h3>
                  Webアプリケーション診断の見積もりをしていただきたいです。診断の見積もりには何を用意すればいいですか。
                </h3>
                <div>
                  以下のような情報をいただけると幸いです。
                  <br />
                  ただ、スケジュールのすり合わせが早めに完了しないとご希望通りの時期に実施できない可能性がございますので、資料が揃っていなくともまずはお問合せください。
                  <ol className={styles.Question_List__Number}>
                    <li>サービスの概要についてわかる資料</li>
                    <li>サービスでどのような技術が使われているかわかる資料</li>
                    <li>リクエスト数の算出を行うために必要な情報</li>
                  </ol>
                  <ol className={styles.Question_List__Alphabet}>
                    <li>サービスの検証環境</li>
                    <li>URL一覧</li>
                    <li>画面仕様書</li>
                    <li>API仕様書</li>
                  </ol>
                  また、これらの見積りに必要な情報について詳しく
                  <a href="/articles/prepare_for_assessment">解説したページ</a>
                  がございますので、よろしければご確認ください。
                </div>
              </div>
              <div>
                <h3>
                  セキュリティ診断で発見された脆弱性を修正しました。再診断は可能ですか。再診断に費用はかかりますか。
                </h3>
                <p>
                  はい、再診断は可能です。また、弊社では報告から1ヶ月以内は再診断を無料でご提供しております。(*診断プラン次第では対象外です)
                </p>
              </div>
              <div>
                <h3>
                  ペネトレーションテストを実施したいのですがセキュリティ診断とは別のものですか？
                </h3>
                <p>
                  我々は別のサービスとして扱っておりますが、お客さまのおっしゃる「ペネトレーションテスト」が我々の提供している「セキュリティ診断」であることは非常に多いです。我々のサービスがお客さまの求めているものに合致するかどうかの確認はお気軽にお問合せください。
                </p>
              </div>
            </FaqStyle>
          </Common2PaneSectionLayout>
        </SubSection>
        <SubSection>
          <Common2PaneSectionLayout>
            <FaqStyle>
              <h2>
                <span>Flatt Securityの特徴や</span>
                <span>他のベンダーとの違いを</span>
                <span>知りたい方向けのよくある質問</span>
              </h2>
              <div>
                <h3>
                  Firebase AuthenticationやCloud Firestore、Amazon
                  Cognito、GraphQL等を用いたアプリケーションの診断は可能ですか。
                </h3>
                <div>
                  はい、可能です。その他の技術スタックを用いている場合でもお気軽にご相談ください。
                  <br />
                  また、以下のページより診断の詳細を確認可能です。
                  <ul>
                    <li>
                      <a href="/assessment/cloud_platform/">
                        AWS・Google Cloud・Azure診断
                      </a>
                    </li>
                    <li>
                      <a href="/assessment/firebase/">Firebase診断</a>
                    </li>
                    <li>
                      <a href="/assessment/graphql/">GraphQL診断</a>
                    </li>
                    <li>
                      <a href="/assessment/spa/">SPA診断</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <h3>報告書のサンプルが見たいです。</h3>
                <p>
                  サンプル報告書をPDF版とMarkdown版でご用意しています。
                  <a href="/assessment/sample_report/">ダウンロードフォーム</a>
                  より無料でダウンロード可能です。
                </p>
              </div>
              <div>
                <h3>Markdownの報告書とはどのようなものですか？</h3>
                <p>
                  指摘事項ごとにmdファイルが存在し、それらがzipにまとめられています。通常、セキュリティ診断はPDFの報告書のみ納品されるケースが多いのですが、その場合JiraやGitHub
                  Issuesといったタスク管理ツールへの起票が難しいという声をいただいていました。これを解決するためにMarkdownの報告書を無償で付帯させていただいております。
                  <br />
                  Markdownのサンプル報告書は
                  <a href="/assessment/sample_report/">ダウンロードフォーム</a>
                  より無料でダウンロード可能です。
                </p>
              </div>
              <div>
                <h3>
                  XSSのような典型的な脆弱性だけでなく、認可制御の不備のようなサービスのロジックに応じた脆弱性も発見してほしいです。こういった調査はオプション料金になりますか？
                </h3>
                <p>
                  いいえ、オプションではありません。Flatt
                  Securityのセキュリティ診断ではロジックに応じた脆弱性も標準で調査いたしますのでご安心ください。
                </p>
              </div>
            </FaqStyle>
          </Common2PaneSectionLayout>
        </SubSection>
      </Section>
    </>
  );
};
