import React, { FC } from "react";
import * as styles from "./index.module.scss";
import { Link } from "gatsby";

export const SubHeader: FC = () => {
  return (
    <>
      <div className={styles.SubHeader}>
        <Link className={styles.SubHeader_Content} to="/assessment#feature">
          特徴
        </Link>
        <Link className={styles.SubHeader_Content} to="/assessment#service">
          メニュー・プラン
        </Link>
        <Link className={styles.SubHeader_Content} to="/assessment#voice">
          事例
        </Link>
        <Link className={styles.SubHeader_Content} to="/assessment#fee">
          料金
        </Link>
        <Link className={styles.SubHeader_Content} to="/assessment#faq">
          FAQ
        </Link>
      </div>
    </>
  );
};
