import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as styles from "./index.module.scss";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";

type Props = {
  quantity?: "six" | "twelve" | "full";
};

export const AssessmentAchievements: React.FC<Props> = (props) => {
  const data: GatsbyAssessmentAchievementsComponentQuery = useStaticQuery(
    graphql`
      query AssessmentAchievementsComponent {
        anique: file(
          relativePath: { eq: "achievements/anique.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        andpad: file(
          relativePath: { eq: "achievements/andpad.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        boostry: file(
          relativePath: { eq: "achievements/boostry.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        doublejumptokyo: file(
          relativePath: { eq: "achievements/double_jump_tokyo.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        enjapan: file(
          relativePath: { eq: "achievements/enjapan.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        finatext: file(
          relativePath: { eq: "achievements/finatext.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        freee: file(
          relativePath: { eq: "achievements/freee.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        gaudiy: file(
          relativePath: { eq: "achievements/gaudiy.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        glory: file(
          relativePath: { eq: "achievements/glory.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        goodpatch: file(
          relativePath: { eq: "achievements/goodpatch.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        kakehashi: file(
          relativePath: { eq: "achievements/kakehashi.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        kaminashi: file(
          relativePath: { eq: "achievements/kaminashi.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        kanmu: file(
          relativePath: { eq: "achievements/kanmu.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        kyodo: file(
          relativePath: { eq: "achievements/kyodo_news_digital.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        liquid: file(
          relativePath: { eq: "achievements/liquid.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        loglass: file(
          relativePath: { eq: "achievements/loglass.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        layerx: file(
          relativePath: { eq: "achievements/layerx.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        mizuho_rt: file(
          relativePath: { eq: "achievements/mizuho_rt.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        mdm: file(
          relativePath: { eq: "achievements/mdm.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        onecareer: file(
          relativePath: { eq: "achievements/onecareer.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        pfn: file(
          relativePath: { eq: "achievements/pfn.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        plaid: file(
          relativePath: { eq: "achievements/plaid.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        primenumber: file(
          relativePath: { eq: "achievements/primenumber.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        sbi: file(
          relativePath: { eq: "achievements/sbi_digitrust.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        shogakukan: file(
          relativePath: { eq: "achievements/shogakukan.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        smartbank: file(
          relativePath: { eq: "achievements/smartbank.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        smarthr: file(
          relativePath: { eq: "achievements/smarthr.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        soracom: file(
          relativePath: { eq: "achievements/soracom.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        stores: file(
          relativePath: { eq: "achievements/stores.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        tokyogas: file(
          relativePath: { eq: "achievements/tokyogas.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        trendmicro: file(
          relativePath: { eq: "achievements/trendmicro.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        ubie: file(
          relativePath: { eq: "achievements/ubie.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `,
  );
  const { quantity } = props;

  if (quantity === "six") {
    return (
      <div className={styles.Achievements}>
        <div className={styles.Achievement_Grid__Partial}>
          <a href="https://corp.en-japan.com/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.enjapan}
                alt="エン・ジャパン"
              />
            </div>
          </a>
          <a href="https://www.tokyo-gas.co.jp/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.tokyogas}
                alt="東京ガス"
              />
            </div>
          </a>
          <a href="https://www.freee.co.jp/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.freee}
                alt="freee"
              />
            </div>
          </a>
          <a href="https://sbidigitrust.com/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.sbi}
                alt="SBIデジトラスト"
              />
            </div>
          </a>
          <a href="https://kanmu.co.jp/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.kanmu}
                alt="kanmu"
              />
            </div>
          </a>
          <a href="https://www.glory.co.jp/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.glory}
                alt="グローリー"
              />
            </div>
          </a>
        </div>
      </div>
    );
  }

  if (quantity === "twelve") {
    return (
      <div className={styles.Achievements}>
        <div className={styles.Achievement_Grid__Partial}>
          <a href="https://www.st.inc/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.stores}
                alt="STORES"
              />
            </div>
          </a>
          <a href="https://smarthr.co.jp/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.smarthr}
                alt="SmartHR"
              />
            </div>
          </a>
          <a href="https://www.doublejump.tokyo/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.doublejumptokyo}
                alt="double jump.tokyo"
              />
            </div>
          </a>
          <a href="https://www.mizuho-rt.co.jp/index.html">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.mizuho_rt}
                alt="みずほリサーチ&テクノロジーズ"
              />
            </div>
          </a>
          <a href="https://corp.mitsui-x.com/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.mdm}
                alt="三井物産デジタル・アセットマネジメント"
              />
            </div>
          </a>
          <a href="https://ubie.life/">
            <div className={styles.Achievement_Item__partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.ubie}
                alt="ユビー"
              />
            </div>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Achievements}>
      <div className={styles.Achievement_Grid}>
        <a href="https://corp.en-japan.com/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.enjapan}
              alt="エン・ジャパン"
            />
          </div>
        </a>
        <a href="https://www.tokyo-gas.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.tokyogas}
              alt="東京ガス"
            />
          </div>
        </a>
        <a href="https://www.freee.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.freee}
              alt="freee"
            />
          </div>
        </a>
        <a href="https://anique.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.anique}
              alt="アニーク"
            />
          </div>
        </a>
        <a href="https://andpad.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.andpad}
              alt="アンドパッド"
            />
          </div>
        </a>
        <a href="https://sbidigitrust.com/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.sbi}
              alt="SBIデジトラスト"
            />
          </div>
        </a>
        <a href="https://gaudiy.com/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.gaudiy}
              alt="Gaudiy"
            />
          </div>
        </a>
        <a href="https://www.kakehashi.life/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.kakehashi}
              alt="カケハシ"
            />
          </div>
        </a>
        <a href="https://corp.kaminashi.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.kaminashi}
              alt="カミナシ"
            />
          </div>
        </a>
        <a href="https://kanmu.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.kanmu}
              alt="kanmu"
            />
          </div>
        </a>
        <a href="https://corp.kyodo-d.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.kyodo}
              alt="共同通信デジタル"
            />
          </div>
        </a>
        <a href="https://goodpatch.com/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.goodpatch}
              alt="グッドパッチ"
            />
          </div>
        </a>
        <a href="https://www.glory.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.glory}
              alt="グローリー"
            />
          </div>
        </a>
        <a href="https://www.st.inc/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.stores}
              alt="STORES"
            />
          </div>
        </a>
        <a href="https://smarthr.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.smarthr}
              alt="SmartHR"
            />
          </div>
        </a>
        <a href="https://smartbank.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.smartbank}
              alt="スマートバンク"
            />
          </div>
        </a>
        <a href="https://soracom.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.soracom}
              alt="ソラコム"
            />
          </div>
        </a>
        <a href="https://www.doublejump.tokyo/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.doublejumptokyo}
              alt="double jump.tokyo"
            />
          </div>
        </a>
        <a href="https://finatext.com/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.finatext}
              alt="Finatextホールディングス"
            />
          </div>
        </a>
        <a href="https://boostry.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.boostry}
              alt="BOOSTRY"
            />
          </div>
        </a>
        <a href="https://primenumber.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.primenumber}
              alt="プライムナンバー"
            />
          </div>
        </a>
        <a href="https://www.preferred.jp/ja/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.pfn}
              alt="Preferred Networks"
            />
          </div>
        </a>
        <a href="https://plaid.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.plaid}
              alt="プレイド"
            />
          </div>
        </a>
        <a href="https://www.mizuho-rt.co.jp/index.html">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.mizuho_rt}
              alt="みずほリサーチ&テクノロジーズ"
            />
          </div>
        </a>
        <a href="https://corp.mitsui-x.com/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.mdm}
              alt="三井物産デジタル・アセットマネジメント"
            />
          </div>
        </a>
        <a href="https://ubie.life/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.ubie}
              alt="ユビー"
            />
          </div>
        </a>
        <a href="https://liquidinc.asia/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.liquid}
              alt="Liquid"
            />
          </div>
        </a>
        <a href="https://layerx.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.layerx}
              alt="LayerX"
            />
          </div>
        </a>
        <a href="https://www.loglass.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.loglass}
              alt="ログラス"
            />
          </div>
        </a>
        <a href="https://onecareer.co.jp/">
          <div className={styles.Achievement_Item}>
            <OptionalGatsbyImage
              className={styles.Achievement_Image}
              data={data.onecareer}
              alt="ワンキャリア"
            />
          </div>
        </a>
      </div>
    </div>
  );
};
