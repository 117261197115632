import React from "react";
import LazyLoad from "react-lazyload";
import { useStaticQuery, graphql } from "gatsby";
import { withContext } from "~context";
import { AssessmentAchievements } from "~components/home/assessment-achievements";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { Interview } from "~components/utils/interview";
import * as styles from "./index.module.scss";

import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import { AssessmentMerit } from "~components/assessment/assessment-detail/merit";
import { AssessmentService } from "~components/assessment/assessment-detail/service";
import { AssessmentDetailFee } from "~components/assessment/assessment-detail/fee";
import { AssessmentDetailQuestion } from "~components/assessment/assessment-detail/question";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { Section, SubSection } from "~components/templates/section";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { DocumentStyle } from "~components/templates/document-style";
import { SubHeader } from "../../components/assessment/assessment-detail/sub-header/index";

const Component: React.FC = () => {
  const data = useStaticQuery(graphql`
    query AssessmentPage {
      category_firebase: file(
        relativePath: { eq: "assessment_detail/bg_firebase.jpg" }
      ) {
        publicURL
      }
    }
  `);

  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "セキュリティ診断(脆弱性診断)",
          description:
            "顧客情報の流出やデータ改ざんに繋がる脆弱性がないかセキュリティエンジニアが診断します。WebやiOS/Androidアプリケーションはもちろん、クラウド環境やIoTに対する診断、ペネトレーションテストまで幅広いご提案が可能です。",
        }}
      >
        <SubHeader />
        <div className={styles.Detail}>
          <div className={styles.Top}>
            <h1 className={styles.Top_Heading}>
              <span className="Top_NewLine">開発者体験に</span>
              <span className="Top_NewLine">徹底的にこだわる</span>
              <br />
              <span className="Top_NewLine">独自スタイルの</span>
              <span className="Top_NewLine">セキュリティ診断</span>
            </h1>
            <div className={styles.Top_Body}>
              Flatt Securityの強みは、
              <a href="#merit_0">
                ブラックボックス形式だけに頼らない独自の診断スタイル
              </a>
              、<a href="#merit_1">高度な技術力</a>、
              <a href="#merit_2">モダンな技術スタックへの対応</a>、
              <a href="#merit_3">開発者目線の丁寧なレポーティング</a>
              です。他社が追随できない開発者体験で、事業/開発のスピードを損なわないセキュリティの実践をサポートします。
            </div>
          </div>

          <div className={styles.Logo}>
            <AssessmentAchievements quantity="six" />
            <div className={styles.Logo_Second}>
              <AssessmentAchievements quantity="twelve" />
            </div>
          </div>

          <div className={styles.LinkTag} id="feature" />
          <LazyLoad>
            <AssessmentMerit />
          </LazyLoad>

          <div className={styles.LinkTag} id="service" />
          <LazyLoad>
            <AssessmentService />
          </LazyLoad>

          <div className={styles.LinkTag} id="voice" />
          <LazyLoad>
            <Interview>
              <AssessmentVoiceList
                slugs={[
                  "ubie",
                  "plaid",
                  "kakehashi",
                  "sbi_digitrust",
                  "tokyogas",
                  "smarthr",
                ]}
              />
            </Interview>
          </LazyLoad>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <SubSection>
                  <DocumentStyle>
                    <h2>
                      セキュリティ診断提供実績
                      <span className={styles.Achievements_Brackets}>
                        (一部)
                      </span>
                    </h2>
                    <p>
                      ベンチャーから大手企業まで、
                      様々な事業者様のセキュリティ診断を担当しています。
                    </p>
                  </DocumentStyle>
                </SubSection>
                <SubSection>
                  <AssessmentAchievements />
                </SubSection>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <div className={styles.LinkTag} id="fee" />
          <LazyLoad>
            <AssessmentDetailFee />
          </LazyLoad>

          <LazyLoad>
            <AssessmentDocContact />
          </LazyLoad>

          <div className={styles.LinkTag} id="faq" />
          <LazyLoad>
            <AssessmentDetailQuestion />
          </LazyLoad>
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export default withContext(Component);
