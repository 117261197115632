import React, { FC } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as styles from "./index.module.scss";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section, SubSection } from "~components/templates/section";
import { DocumentStyle } from "~components/templates/document-style";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";

export const AssessmentService: FC = () => {
  const data: GatsbyAssessmentServiceComponentQuery = useStaticQuery(graphql`
    query AssessmentServiceComponent {
      aws: file(relativePath: { eq: "assessment/cloud_platform_aws.svg" }) {
        publicURL
      }
      gcp: file(relativePath: { eq: "assessment/cloud_platform_gcp.png" }) {
        publicURL
      }
      firebase: file(
        relativePath: { eq: "assessment/firebase_built_logo.png" }
      ) {
        publicURL
      }
      category_cloudpf: file(
        relativePath: { eq: "assessment_detail/bg_cloud_platfrom.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_firebase: file(
        relativePath: { eq: "assessment_detail/bg_firebase.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_1: file(
        relativePath: { eq: "assessment_detail/bg_web_app.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_2: file(
        relativePath: { eq: "assessment_detail/bg_platfrom.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_4: file(
        relativePath: { eq: "assessment_detail/bg_sp_app.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_5: file(
        relativePath: { eq: "assessment_detail/bg_sp_game.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_6: file(relativePath: { eq: "assessment_detail/bg_iot.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_8: file(
        relativePath: { eq: "assessment_detail/bg_pentest.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_7: file(
        relativePath: { eq: "assessment_detail/bg_whitebox.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_10: file(
        relativePath: { eq: "assessment_detail/bg_graphql.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_11: file(
        relativePath: { eq: "assessment_detail/bg_blockchain.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_12: file(relativePath: { eq: "assessment_detail/bg_spa.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      category_13: file(
        relativePath: { eq: "assessment_detail/bg_risk_focus.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      graphql_logo: file(
        relativePath: { eq: "assessment_detail/graphql_logo.svg" }
      ) {
        publicURL
      }
      vue_logo: file(relativePath: { eq: "assessment_detail/vue.svg" }) {
        publicURL
      }
      react_logo: file(relativePath: { eq: "assessment_detail/react.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <Section>
      <SubSection>
        <Common2PaneSectionLayout width="large">
          <DocumentStyle>
            <h3>クラウド診断</h3>
          </DocumentStyle>
          <ul className={styles.Service_Plans}>
            <li className={styles.Service_Item}>
              <div className={styles.Service_BackgroundWrapper}>
                <OptionalGatsbyImage
                  className={styles.Service_Background}
                  data={data.category_cloudpf}
                  alt=""
                />
              </div>
              <Link
                to="/assessment/cloud_platform"
                className={styles.Service_Link}
              >
                <div className={styles.Service_TwoLogo}>
                  <div className={styles.Service_TwoLogoBg}>
                    <img
                      src={data?.aws?.publicURL || ""}
                      alt=""
                      className={styles.Service_LogoImage}
                    />
                  </div>
                  <div className={styles.Service_TwoLogoBg}>
                    <img
                      src={data?.gcp?.publicURL || ""}
                      alt=""
                      className={styles.Service_LogoImage}
                    />
                  </div>
                </div>
                <div className={styles.Service_ItemDetail}>
                  <h4 className={styles.Service_ItemTitle}>
                    AWS・Google Cloud・Azure
                  </h4>
                  <p className={styles.Service_ItemDescription}>
                    構成や設定ミスを洗い出す診断をはじめとして、AWS・Google
                    Cloud・Azure等のパブリッククラウド固有の診断を提供します。
                  </p>
                </div>
              </Link>
            </li>
            <li className={styles.Service_Item}>
              <div className={styles.Service_BackgroundWrapper}>
                <OptionalGatsbyImage
                  className={styles.Service_Background}
                  data={data.category_firebase}
                  alt=""
                />
              </div>

              <Link to="/assessment/firebase" className={styles.Service_Link}>
                <div className={styles.Service_FirebaseLogo}>
                  <img
                    src={data?.firebase?.publicURL || ""}
                    alt=""
                    className={styles.Service_FirebaseLogoImage}
                  />
                </div>
                <div className={styles.Service_ItemDetail}>
                  <h4 className={styles.Service_ItemTitle}>Firebase</h4>
                  <p className={styles.Service_ItemDescription}>
                    FirestoreのセキュリティルールやCloud
                    Functionsの実装など、Firebaseを対象に独自の診断を実施します。
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </Common2PaneSectionLayout>
      </SubSection>
      <SubSection>
        <Common2PaneSectionLayout width="large">
          <div className={styles.Service}>
            <DocumentStyle>
              <h3>アプリケーション診断・その他</h3>
            </DocumentStyle>
            <ul className={styles.Service_CategoryList}>
              <li className={styles.Service_Item}>
                <div className={styles.Service_BackgroundWrapper}>
                  <OptionalGatsbyImage
                    className={styles.Service_Background}
                    data={data.category_1}
                    alt=""
                  />
                </div>
                <Link to="/assessment/web_app" className={styles.Service_Link}>
                  <div className={styles.Service_Item}>
                    <h4 className={styles.Service_ItemTitle}>
                      Webアプリケーション
                    </h4>
                    <p className={styles.Service_ItemDescription}>
                      Webアプリケーションの実装・通信に脆弱性がないか専門のセキュリティエンジニアが診断します。
                    </p>
                  </div>
                </Link>
              </li>
              <li className={styles.Service_Item}>
                <div className={styles.Service_BackgroundWrapper}>
                  <OptionalGatsbyImage
                    className={styles.Service_Background}
                    data={data.category_4}
                    alt=""
                  />
                </div>

                <Link to="/assessment/sp_app" className={styles.Service_Link}>
                  <div className={styles.Service_Item}>
                    <h4 className={styles.Service_ItemTitle}>
                      スマートフォンアプリケーション
                    </h4>
                    <p className={styles.Service_ItemDescription}>
                      iOS/Androidネイティブアプリケーション及びAPIの脆弱性を発見します。
                    </p>
                  </div>
                </Link>
              </li>
              <li className={styles.Service_Item}>
                <div className={styles.Service_BackgroundWrapper}>
                  <OptionalGatsbyImage
                    className={styles.Service_Background}
                    data={data.category_5}
                    alt=""
                  />
                </div>
                <Link to="/assessment/sp_game" className={styles.Service_Link}>
                  <div className={styles.Service_Item}>
                    <h4 className={styles.Service_ItemTitle}>
                      スマートフォンゲーム
                    </h4>
                    <p className={styles.Service_ItemDescription}>
                      ゲームのセキュリティ対策が適切かどうか経験豊富なエンジニアが診断を行います
                    </p>
                  </div>
                </Link>
              </li>
              <li className={styles.Service_Item}>
                <div className={styles.Service_BackgroundWrapper}>
                  <OptionalGatsbyImage
                    className={styles.Service_Background}
                    data={data.category_2}
                    alt=""
                  />
                </div>
                <Link to="/assessment/platform" className={styles.Service_Link}>
                  <div className={styles.Service_Item}>
                    <h4 className={styles.Service_ItemTitle}>
                      プラットフォーム
                    </h4>
                    <p className={styles.Service_ItemDescription}>
                      サーバーの設定ミスやミドルウェア、OSの脆弱性を発見します。
                    </p>
                  </div>
                </Link>
              </li>
              <li className={styles.Service_Item}>
                <div className={styles.Service_BackgroundWrapper}>
                  <OptionalGatsbyImage
                    className={styles.Service_Background}
                    data={data.category_6}
                    alt=""
                  />
                </div>
                <Link to="/assessment/iot" className={styles.Service_Link}>
                  <div className={styles.Service_Item}>
                    <h4 className={styles.Service_ItemTitle}>IoT</h4>
                    <p className={styles.Service_ItemDescription}>
                      IoT機器および関連するシステムに脆弱性がないか診断します
                    </p>
                  </div>
                </Link>
              </li>
              <li className={styles.Service_Item}>
                <div className={styles.Service_BackgroundWrapper}>
                  <OptionalGatsbyImage
                    className={styles.Service_Background}
                    data={data.category_8}
                    alt=""
                  />
                </div>
                <Link
                  to="/assessment/penetration_test"
                  className={styles.Service_Link}
                >
                  <div className={styles.Service_Item}>
                    <h4 className={styles.Service_ItemTitle}>
                      ペネトレーションテスト
                    </h4>
                    <p className={styles.Service_ItemDescription}>
                      環境に合わせた様々な技術を用いて、システムへの侵入・特権の奪取など実在するリスクを見つけ出します。
                    </p>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </Common2PaneSectionLayout>
      </SubSection>
      <SubSection>
        <Common2PaneSectionLayout width="large">
          <DocumentStyle align="center">
            <h3>技術スタックに応じたメニュー</h3>
          </DocumentStyle>
          <ul className={styles.Service_Plans}>
            <li className={styles.Service_Item}>
              <div className={styles.Service_BackgroundWrapper}>
                <OptionalGatsbyImage
                  className={styles.Service_Background}
                  data={data.category_12}
                  alt=""
                />
              </div>
              <Link to="/assessment/spa" className={styles.Service_Link}>
                <div className={styles.Service_TwoLogo}>
                  <div className={styles.Service_TwoLogoBg}>
                    <img
                      src={data?.vue_logo?.publicURL || ""}
                      alt=""
                      className={styles.Service_LogoImage}
                    />
                  </div>
                  <div className={styles.Service_TwoLogoBg}>
                    <img
                      src={data?.react_logo?.publicURL || ""}
                      alt=""
                      className={styles.Service_LogoImage}
                    />
                  </div>
                </div>
                <div className={styles.Service_ItemDetail}>
                  <h4 className={styles.Service_ItemTitle}>SPA</h4>
                  <p className={styles.Service_ItemDescription}>
                    SPAのソースコードを読むことによるホワイトボックス形式の診断を提供します。
                  </p>
                </div>
              </Link>
            </li>
            <li className={styles.Service_Item}>
              <div className={styles.Service_BackgroundWrapper}>
                <OptionalGatsbyImage
                  className={styles.Service_Background}
                  data={data.category_10}
                  alt=""
                />
              </div>
              <Link to="/assessment/graphql" className={styles.Service_Link}>
                <div className={styles.Service_GraphqlLogo}>
                  <img
                    src={data?.graphql_logo?.publicURL || ""}
                    alt=""
                    className={styles.Service_GraphqlLogoImage}
                  />
                </div>
                <div className={styles.Service_ItemDetail}>
                  <h4 className={styles.Service_ItemTitle}>GraphQL</h4>
                  <p className={styles.Service_ItemDescription}>
                    Webアプリケーションのセキュリティ診断観点に、GraphQL利用時に起こりうる特有の観点を加え、専門のセキュリティエンジニアが診断します。
                  </p>
                </div>
              </Link>
            </li>
            <li className={styles.Service_Item}>
              <div className={styles.Service_BackgroundWrapper}>
                <OptionalGatsbyImage
                  className={styles.Service_Background}
                  data={data.category_11}
                  alt=""
                />
              </div>
              <Link to="/assessment/blockchain" className={styles.Service_Link}>
                <div className={styles.Service_Item}>
                  <h4 className={styles.Service_ItemTitle}>
                    ブロックチェーン診断
                  </h4>
                  <p className={styles.Service_ItemDescription}>
                    ブロックチェーン技術に関連するWebアプリケーションや、Solidity等で実装されたスマートコントラクトに脆弱性がないか診断します。
                  </p>
                </div>
              </Link>
            </li>
          </ul>
          <p className={styles.Service_LogoLicense}>
            Vue.js logo: ©︎ Evan You (
            <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">
              CC BY-NC-SA 4.0
            </a>
            ) with extra conditions(It’s OK to illustrate a commercial product’s
            Vue.js integration in its marketing copy.) / React logo: ©︎ Meta
            Platforms, Inc. (
            <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>
            )
          </p>
        </Common2PaneSectionLayout>
      </SubSection>
      <SubSection>
        <Common2PaneSectionLayout width="large">
          <DocumentStyle align="center">
            <h3>様々なニーズに応えるプラン</h3>
          </DocumentStyle>
          <ul className={styles.Service_Plans}>
            <li className={styles.Service_Item}>
              <div className={styles.Service_BackgroundWrapper}>
                <OptionalGatsbyImage
                  className={styles.Service_Background}
                  data={data.category_7}
                  alt=""
                />
              </div>
              <Link
                to="/assessment/plan/whitebox"
                className={styles.Service_Link}
              >
                <div className={styles.Service_Item}>
                  <h4 className={styles.Service_ItemTitle}>
                    ホワイトボックス診断
                  </h4>
                  <p className={styles.Service_ItemDescription}>
                    ソースコードやシステムの設計、仕様書などを提供していただき、それらの分析を通して診断を実施します。
                  </p>
                </div>
              </Link>
            </li>
            <li className={styles.Service_Item}>
              <div className={styles.Service_BackgroundWrapper}>
                <OptionalGatsbyImage
                  className={styles.Service_Background}
                  data={data.category_13}
                  alt=""
                />
              </div>
              <Link
                to="/assessment/plan/risk_focus"
                className={styles.Service_Link}
              >
                <div className={styles.Service_Item}>
                  <h4 className={styles.Service_ItemTitle}>
                    リスクフォーカス型プラン
                  </h4>
                  <p className={styles.Service_ItemDescription}>
                    定められた期間内に、可能な限りビジネスリスクの高い脆弱性を探すセキュリティ診断の手法です。
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </Common2PaneSectionLayout>
      </SubSection>
    </Section>
  );
};
