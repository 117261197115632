import React, { FC } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as styles from "./index.module.scss";

import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";
import { Button } from "~components/utils/button";
import { DocumentStyle } from "~components/templates/document-style";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section, SubSection } from "~components/templates/section";

export const AssessmentMerit: FC = () => {
  const data: GatsbyAssessmentMeritComponentQuery = useStaticQuery(graphql`
    query AssessmentMeritComponent {
      merit_0: file(
        relativePath: { eq: "assessment_detail/merit_0.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_1: file(
        relativePath: { eq: "assessment_detail/merit_1.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_2: file(
        relativePath: { eq: "assessment_detail/merit_2.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_3: file(
        relativePath: { eq: "assessment_detail/merit_3.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_face1: file(
        relativePath: { eq: "assessment_detail/merit_face1.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_face2: file(
        relativePath: { eq: "assessment_detail/merit_face2.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_face3: file(
        relativePath: { eq: "assessment_detail/merit_face3.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_face4: file(
        relativePath: { eq: "assessment_detail/merit_face4.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_face5: file(
        relativePath: { eq: "assessment_detail/merit_face5.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_face6: file(
        relativePath: { eq: "assessment_detail/merit_face6.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_face7: file(
        relativePath: { eq: "assessment_detail/merit_face7.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      merit_face8: file(
        relativePath: { eq: "assessment_detail/merit_face8.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      slack: file(
        relativePath: { eq: "assessment_detail/slack.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      sssmark: file(
        relativePath: { eq: "assessment_detail/sssmark.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <div className={styles.Merit}>
      <Section>
        <SubSection>
          <Common2PaneSectionLayout>
            <div className={styles.Merit_Gap}>
              <div id="merit_0">
                <div className={styles.Merit_Wrapper}>
                  <DocumentStyle>
                    <h2>
                      ソースコード診断を
                      <span>無料で付帯し、</span>
                      <br /> 提供価値を最大化します
                    </h2>
                    <p>
                      セキュリティ診断業界では、外部から得られる情報だけを用いて攻撃を試みる「ブラックボックス診断」のみを行うケースが通例となっていますが、Flatt
                      Securityの診断では都度ソースコードの参照も行う診断を標準プランとしており、追加料金もいただきません。いただくご予算の中で最大の網羅性を追求した効率的な診断スタイルが特徴です。
                    </p>
                    <div className={styles.Merit_ImageMerit0Wrapper}>
                      <OptionalGatsbyImage
                        className={styles.Merit_ImageMerit0}
                        data={data.merit_0}
                        alt=""
                      />
                    </div>
                  </DocumentStyle>
                </div>
                <div className={styles.Merit_VoiceWrapper}>
                  <div className={styles.Merit_Voice}>
                    <div className={styles.Merit_ImageFaceWrapper}>
                      <OptionalGatsbyImage
                        className={styles.Merit_ImageFace}
                        data={data.merit_face1}
                        alt="face_ubie"
                      />
                    </div>
                    <div className={styles.Merit_VoiceContents}>
                      <p className={styles.Merit_VoiceWho}>
                        Ubie様 / Webアプリケーション診断
                      </p>
                      <p className={styles.Merit_VoiceHow}>
                        プロダクトの挙動についての確認作業を減らしたり、外部の動作からだけでは判断が難しい脆弱性も発見してもらっています。
                      </p>
                    </div>
                  </div>
                  <div className={styles.Merit_Voice}>
                    <div className={styles.Merit_ImageFaceWrapper}>
                      <OptionalGatsbyImage
                        className={styles.Merit_ImageFace}
                        data={data.merit_face2}
                        alt="face_tier_iv"
                      />
                    </div>
                    <div className={styles.Merit_VoiceContents}>
                      <p className={styles.Merit_VoiceWho}>
                        TIER IV様 / Webアプリケーション診断
                      </p>
                      <p className={styles.Merit_VoiceHow}>
                        ソースコードを提供する事に不安はありましたが、詳細な説明が無くても的確に読み解いて未知の脆弱性を指摘してもらえました。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="merit_1">
                <div className={styles.Merit_Wrapper}>
                  <div className={styles.Merit_ImageMerit1Wrapper}>
                    <OptionalGatsbyImage
                      className={styles.Merit_ImageMerit1}
                      data={data.merit_1}
                      alt=""
                    />
                  </div>

                  <div className={styles.Merit_Contents}>
                    <DocumentStyle align="left" width="no-limit">
                      <h2>
                        独自の診断スタイルを支えるのは
                        <br className={styles.Sp} />
                        実績に裏打ちされた技術力です
                      </h2>
                      <p>
                        Flatt Securityは日頃から
                        <a href="/cve">多くのプロダクトの脆弱性を報告</a>
                        したり、海外のハッキングコンテストで
                        <a href="https://scan.netsecurity.ne.jp/article/2021/05/27/45729.html">
                          30,000USドルを獲得
                        </a>
                        したりと、実績に裏打ちされた技術力を持つエンジニア集団です。手動とツールを組み合わせた診断を行い、網羅的に脆弱性を見つけ出します。
                      </p>
                    </DocumentStyle>
                  </div>
                </div>
                <div className={styles.Merit_VoiceWrapper}>
                  <div className={styles.Merit_Voice}>
                    <div className={styles.Merit_ImageFaceWrapper}>
                      <OptionalGatsbyImage
                        className={styles.Merit_ImageFace}
                        data={data.merit_face3}
                        alt="face_glory"
                      />
                    </div>
                    <div className={styles.Merit_VoiceContents}>
                      <p className={styles.Merit_VoiceWho}>
                        グローリー様 / アプリケーション診断
                      </p>
                      <p className={styles.Merit_VoiceHow}>
                        「脆弱性リサーチプロジェクト」の実績を見て、技術力の高さを確認することができました。
                      </p>
                    </div>
                  </div>
                  <div className={styles.Merit_Voice}>
                    <div className={styles.Merit_ImageFaceWrapper}>
                      <OptionalGatsbyImage
                        className={styles.Merit_ImageFace}
                        data={data.merit_face4}
                        alt="face_freee"
                      />
                    </div>
                    <div className={styles.Merit_VoiceContents}>
                      <p className={styles.Merit_VoiceWho}>
                        freee様 / スマートフォンアプリケーション診断
                      </p>
                      <p className={styles.Merit_VoiceHow}>
                        今回の診断結果で確かな実力があることが改めてわかり、年間契約に踏み切りました。
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="merit_2">
                <div className={styles.Merit_Wrapper__Reverse}>
                  <div className={styles.Merit_ImageMerit2Wrapper}>
                    <OptionalGatsbyImage
                      className={styles.Merit_ImageMerit2}
                      data={data.merit_2}
                      alt=""
                    />
                  </div>

                  <div className={styles.Merit_Contents}>
                    <DocumentStyle align="left" width="no-limit">
                      <h2>
                        技術スタックや予算に合わせ
                        <br className={styles.Sp} />
                        オーダーメイドの
                        <br className={styles.Sp} />
                        診断プランを提案します
                      </h2>
                      <p>
                        本質的なセキュリティ対策は、プロダクトごとに異なることが少なくありません。AWS・Google
                        Cloud・Firebase・SPA・GraphQLといった技術スタックや予算、その他制約に合わせてオーダーメイドの診断プランを提案します。積極的な提案は当社の強みです。
                      </p>
                    </DocumentStyle>
                  </div>
                </div>
                <div className={styles.Merit_VoiceWrapper}>
                  <div className={styles.Merit_Voice}>
                    <div className={styles.Merit_ImageFaceWrapper}>
                      <OptionalGatsbyImage
                        className={styles.Merit_ImageFace}
                        data={data.merit_face5}
                        alt="face_overflow"
                      />
                    </div>
                    <div className={styles.Merit_VoiceContents}>
                      <p className={styles.Merit_VoiceWho}>
                        overflow様 / Webアプリケーション診断
                      </p>
                      <p className={styles.Merit_VoiceHow}>
                        複数の診断会社に見積もりを依頼したところ、Flatt
                        Securityさんがもっとも提案レベルの高い内容のものを送ってくれました。
                      </p>
                    </div>
                  </div>
                  <div className={styles.Merit_Voice}>
                    <div className={styles.Merit_ImageFaceWrapper}>
                      <OptionalGatsbyImage
                        className={styles.Merit_ImageFace}
                        data={data.merit_face6}
                        alt="face_enjapan"
                      />
                    </div>
                    <div className={styles.Merit_VoiceContents}>
                      <p className={styles.Merit_VoiceWho}>
                        エン・ジャパン様 / Firebase + Webアプリケーション診断
                      </p>
                      <p className={styles.Merit_VoiceHow}>
                        Firebaseは独自のセキュリティ機構を持っているので、専門的に診断できる企業に依頼したいと思っており、探し当てた企業の中のひとつがFlatt
                        Securityさんでした。
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="merit_3">
                <div className={styles.Merit_Wrapper}>
                  <div className={styles.Merit_ImageMerit3Wrapper}>
                    <OptionalGatsbyImage
                      className={styles.Merit_ImageMerit3}
                      data={data.merit_3}
                      alt=""
                    />
                  </div>

                  <div className={styles.Merit_Contents}>
                    <DocumentStyle align="left" width="no-limit">
                      <h2>
                        脆弱性再現方法や改修優先度が
                        <br className={styles.Sp} />
                        一目でわかる
                        <br className={styles.Sp} />
                        開発者フレンドリーな報告書
                      </h2>
                      <p>
                        Flatt
                        Securityの報告書では、脆弱性の再現方法がコピー&ペーストで動作するPoCコードで示され、PDF版に加えてMarkdown版報告書も提供されます。また、「攻撃成立の可能性」という指標により改修の優先度判断が容易となり、開発者の負担を最小化します。
                      </p>
                    </DocumentStyle>
                  </div>
                </div>
                <div className={styles.Merit_VoiceWrapper}>
                  <div className={styles.Merit_Voice}>
                    <div className={styles.Merit_ImageFaceWrapper}>
                      <OptionalGatsbyImage
                        className={styles.Merit_ImageFace}
                        data={data.merit_face7}
                        alt="face_mitsui"
                      />
                    </div>
                    <div className={styles.Merit_VoiceContents}>
                      <p className={styles.Merit_VoiceWho}>
                        三井物産デジタル・アセットマネジメント様 /
                        Webアプリケーション診断
                      </p>
                      <p className={styles.Merit_VoiceHow}>
                        「攻撃成立の可能性」について、あまり言及するようなレポートはないような気がしていますので、判断の参考にしやすいと思いました
                      </p>
                    </div>
                  </div>
                  <div className={styles.Merit_Voice}>
                    <div className={styles.Merit_ImageFaceWrapper}>
                      <OptionalGatsbyImage
                        className={styles.Merit_ImageFace}
                        data={data.merit_face8}
                        alt="face_finatext"
                      />
                    </div>
                    <div className={styles.Merit_VoiceContents}>
                      <p className={styles.Merit_VoiceWho}>
                        Finatextホールディングス様 / Webアプリケーション診断
                      </p>
                      <p className={styles.Merit_VoiceHow}>
                        Flatt
                        Securityさんから提供頂いたレポートはノイズが少なく、大幅に修正対応の時間を短縮できました。
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.Merit_SampleReport}>
                  <Link to="/assessment/sample_report">
                    <Button buttonSize="Large" buttonTheme="Gradient">
                      サンプル報告書を無料ダウンロード
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Common2PaneSectionLayout>
        </SubSection>
        <SubSection>
          <Common2PaneSectionLayout>
            <div className={styles.MeritOthers}>
              <div className={styles.MeritOthers_Container}>
                <div className={styles.MeritOthers_Wrapper}>
                  <DocumentStyle>
                    <h3>
                      Slackでセキュリティエンジニアとの直接コミュニケーションが可能
                    </h3>
                    <p>
                      セキュリティ診断のお見積もり、進行には複数のメンバーを巻き込んだ細かなコミュニケーションが必要です。早期の段階からSlack上で当社セキュリティエンジニアも含むコミュニケーションを行い、負担を軽減することが可能です。
                    </p>
                  </DocumentStyle>
                  <OptionalGatsbyImage
                    className={styles.MeritOthers_Image}
                    data={data.slack}
                    alt="slack"
                  />
                </div>
                <div className={styles.MeritOthers_Wrapper}>
                  <DocumentStyle>
                    <h3>経済産業省が策定したセキュリティサービス基準に適合</h3>
                    <p>
                      Webアプリケーション診断・スマートフォンアプリケーション診断・プラットフォーム診断は経済産業省が定める「情報セキュリティサービス基準」に適合しており、安心してサービスをご利用いただけます。
                    </p>
                  </DocumentStyle>
                  <OptionalGatsbyImage
                    className={styles.MeritOthers_Image}
                    data={data.sssmark}
                    alt="情報セキュリティサービスマーク"
                  />
                </div>
              </div>
            </div>
          </Common2PaneSectionLayout>
        </SubSection>
      </Section>
    </div>
  );
};
