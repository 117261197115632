// extracted by mini-css-extract-plugin
export var Fee = "index-module--Fee--97xcs";
export var FeeDetail_ChartImage = "index-module--FeeDetail_ChartImage--8xEXs";
export var FeeDetail_Image = "index-module--FeeDetail_Image--ZFkAT";
export var FeeDetail_Note = "index-module--FeeDetail_Note--nHTTm";
export var FeeDetail_Price = "index-module--FeeDetail_Price--SDIKn";
export var FeeDetail_PriceItem = "index-module--FeeDetail_PriceItem--feSIe";
export var FeeDetail_PriceWrapper = "index-module--FeeDetail_PriceWrapper--5oOHv";
export var FeeDetail_RequestBackground = "index-module--FeeDetail_RequestBackground--oyTC+";
export var FeeDetail_RequestImg = "index-module--FeeDetail_RequestImg--ha8LP";
export var FeeDetail_RequestTitle = "index-module--FeeDetail_RequestTitle--FSlPu";
export var FeeDetail_RequestTop = "index-module--FeeDetail_RequestTop--Pa41S";
export var FeeDetail_RequestTopFlex = "index-module--FeeDetail_RequestTopFlex--QbK+r";
export var Fee_Body = "index-module--Fee_Body--x6U2I";
export var Fee_H4 = "index-module--Fee_H4--44F6V";
export var Fee_Header = "index-module--Fee_Header--MDUk1";
export var Fee_Price = "index-module--Fee_Price--tN5a+";
export var Fee_RequestExample = "index-module--Fee_RequestExample--AE8A6";
export var Fee_RequestExampleWrapper = "index-module--Fee_RequestExampleWrapper--6lmiI";
export var Fee_RequestH5 = "index-module--Fee_RequestH5--i08cj";
export var Fee_RequestImage = "index-module--Fee_RequestImage--XpzEc";
export var Fee_RequestImageWrapper = "index-module--Fee_RequestImageWrapper--6MMOi";
export var Fee_RequestPrimary = "index-module--Fee_RequestPrimary--zVuzO";
export var Fee_RequestWrapper = "index-module--Fee_RequestWrapper--uKjwo";
export var Fee_SmallText = "index-module--Fee_SmallText--Y89Xq";
export var Fee_Title = "index-module--Fee_Title--lkUN3";
export var Fee_Wrapper = "index-module--Fee_Wrapper--2e-Wr";