// extracted by mini-css-extract-plugin
export var Service = "index-module--Service--5zafV";
export var Service_Background = "index-module--Service_Background--S58Rw";
export var Service_BackgroundWrapper = "index-module--Service_BackgroundWrapper--vOujV";
export var Service_CategoryList = "index-module--Service_CategoryList--ETacI";
export var Service_FirebaseLogo = "index-module--Service_FirebaseLogo--vhXsL";
export var Service_FirebaseLogoImage = "index-module--Service_FirebaseLogoImage--UqgFF";
export var Service_GraphqlLogo = "index-module--Service_GraphqlLogo--BDkmo";
export var Service_GraphqlLogoImage = "index-module--Service_GraphqlLogoImage--Udz8k";
export var Service_Item = "index-module--Service_Item--buB5r";
export var Service_ItemDescription = "index-module--Service_ItemDescription--CVtku";
export var Service_ItemDetail = "index-module--Service_ItemDetail--DxAOF";
export var Service_ItemTitle = "index-module--Service_ItemTitle--JIFlN";
export var Service_Link = "index-module--Service_Link--CjNs+";
export var Service_LogoImage = "index-module--Service_LogoImage--GeTSL";
export var Service_LogoLicense = "index-module--Service_LogoLicense--ELVo5";
export var Service_Plans = "index-module--Service_Plans--EsBiC";
export var Service_TwoLogo = "index-module--Service_TwoLogo--BRJOK";
export var Service_TwoLogoBg = "index-module--Service_TwoLogoBg--yKrka";