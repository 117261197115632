// extracted by mini-css-extract-plugin
export var Achievements_Brackets = "index-module--Achievements_Brackets--gQhqP";
export var AssessmentCommonContact_Title = "index-module--AssessmentCommonContact_Title--9sHws";
export var AssessmentCommonQuickContact = "index-module--AssessmentCommonQuickContact--x7Or7";
export var Detail = "index-module--Detail--48XDF";
export var LinkTag = "index-module--LinkTag--mb2DP";
export var Logo = "index-module--Logo--Wpf7l";
export var Logo_Second = "index-module--Logo_Second--wczeo";
export var Process = "index-module--Process--7ExlW";
export var Process_Heading = "index-module--Process_Heading--J7xEq";
export var Process_StepList = "index-module--Process_StepList--iALc-";
export var Process_Wrapper = "index-module--Process_Wrapper--Oz6ES";
export var SubHeader = "index-module--SubHeader--EMvkx";
export var Top = "index-module--Top--u769i";
export var Top_Body = "index-module--Top_Body--af-yc";
export var Top_EscapeLink = "index-module--Top_EscapeLink--wt4Ic";
export var Top_Heading = "index-module--Top_Heading--UFauP";
export var Top_NewLine = "index-module--Top_NewLine--PAWbZ";