// extracted by mini-css-extract-plugin
export var Merit = "index-module--Merit--5e7-J";
export var MeritOthers_Container = "index-module--MeritOthers_Container--ENwie";
export var MeritOthers_Image = "index-module--MeritOthers_Image--ZUzCz";
export var MeritOthers_Wrapper = "index-module--MeritOthers_Wrapper--9THie";
export var Merit_Gap = "index-module--Merit_Gap--OCqMU";
export var Merit_ImageFace = "index-module--Merit_ImageFace--ZBUxD";
export var Merit_ImageFaceWrapper = "index-module--Merit_ImageFaceWrapper--VzYsX";
export var Merit_ImageLeft = "index-module--Merit_ImageLeft--CGYzU";
export var Merit_ImageMerit0 = "index-module--Merit_ImageMerit0--TdKY3";
export var Merit_ImageMerit0Wrapper = "index-module--Merit_ImageMerit0Wrapper--BXImA";
export var Merit_ImageMerit1 = "index-module--Merit_ImageMerit1--mrVjD";
export var Merit_ImageMerit1Wrapper = "index-module--Merit_ImageMerit1Wrapper--OSqJS";
export var Merit_ImageMerit2 = "index-module--Merit_ImageMerit2--+mAT3";
export var Merit_ImageMerit2Wrapper = "index-module--Merit_ImageMerit2Wrapper--eyEIS";
export var Merit_ImageMerit3 = "index-module--Merit_ImageMerit3--1yeMA";
export var Merit_ImageMerit3Wrapper = "index-module--Merit_ImageMerit3Wrapper--tinxe";
export var Merit_SampleReport = "index-module--Merit_SampleReport--+MC57";
export var Merit_Voice = "index-module--Merit_Voice--W4wui";
export var Merit_VoiceContents = "index-module--Merit_VoiceContents--mGDRE";
export var Merit_VoiceHow = "index-module--Merit_VoiceHow--NBzdw";
export var Merit_VoiceWho = "index-module--Merit_VoiceWho--ntzb4";
export var Merit_VoiceWrapper = "index-module--Merit_VoiceWrapper--u1tz5";
export var Merit_Wrapper = "index-module--Merit_Wrapper--Lroda";
export var Merit_Wrapper__Reverse = "index-module--Merit_Wrapper__Reverse--gAlz0";
export var Sp = "index-module--Sp--4N83M";