import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as styles from "./index.module.scss";
import { FadeInAnimation } from "~components/utils/fade-in-animation";
import { DocumentStyle } from "~components/templates/document-style";
import { Section, SubSection } from "~components/templates/section";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";

export const AssessmentDetailFee: FC = () => {
  const data: GatsbyAssessmentDetailFeeComponentQuery = useStaticQuery(graphql`
    query AssessmentDetailFeeComponent {
      pc: file(relativePath: { eq: "assessment_detail/pc.png" }) {
        publicURL
      }
      request_1: file(relativePath: { eq: "request_1.png" }) {
        publicURL
      }
      request_2: file(relativePath: { eq: "request_2.png" }) {
        publicURL
      }
      merit_2: file(relativePath: { eq: "assessment_detail/merit_2.png" }) {
        publicURL
      }
    }
  `);

  return (
    <Section background="brand">
      <div className={styles.Fee}>
        <Common2PaneSectionLayout>
          <SubSection>
            <DocumentStyle width="no-limit">
              <h2>
                <span>予算・スケジュール・その他制約に応じて</span>
                <span>最適なお見積もりを提案します。まずはご相談ください</span>
              </h2>
            </DocumentStyle>
            <img
              className={styles.FeeDetail_ChartImage}
              src="https://flatt.tech/assets/images/assessment/price_distribution.png"
              alt="セキュリティ診断の値段帯ごとの割合"
            />
          </SubSection>

          <SubSection>
            <DocumentStyle>
              <h3>Webアプリケーション診断の料金例</h3>
            </DocumentStyle>
            <div className={styles.FeeDetail_PriceWrapper}>
              <div className={styles.FeeDetail_PriceItem}>
                <img
                  className={styles.FeeDetail_Image}
                  src={data?.pc?.publicURL || ""}
                  alt=""
                />
                <div className={styles.FeeDetail_PriceContents}>
                  <div className={styles.FeeDetail_Price}>
                    50<span className={styles.Fee_SmallText}>万円</span>
                  </div>
                  <p className={styles.FeeDetail_PriceDetail}>
                    コーポレートサイト
                    <br />
                    10画面
                  </p>
                </div>
              </div>
              <div className={styles.FeeDetail_PriceItem}>
                <img
                  className={styles.FeeDetail_Image}
                  src={data?.pc?.publicURL || ""}
                  alt=""
                />
                <div className={styles.FeeDetail_PriceContents}>
                  <div className={styles.FeeDetail_Price}>
                    140
                    <span className={styles.Fee_SmallText}>万円</span>
                  </div>
                  <p className={styles.FeeDetail_PriceDetail}>
                    SNS/コンテンツ配信サイト
                    <br />
                    35画面
                  </p>
                </div>
              </div>
              <div className={styles.FeeDetail_PriceItem}>
                <img
                  className={styles.FeeDetail_Image}
                  src={data?.pc?.publicURL || ""}
                  alt=""
                />
                <div className={styles.FeeDetail_PriceContents}>
                  <div className={styles.FeeDetail_Price}>
                    300
                    <span className={styles.Fee_SmallText}>万円</span>
                  </div>
                  <p className={styles.FeeDetail_PriceDetail}>
                    ECサイト
                    <br />
                    80画面
                  </p>
                </div>
              </div>
            </div>
          </SubSection>

          <SubSection>
            <DocumentStyle>
              <h3>Webアプリケーション診断の料金計算方法</h3>
            </DocumentStyle>
            <div className={styles.Fee_RequestWrapper}>
              <div className={styles.FeeDetail_RequestTop}>
                <div className={styles.FeeDetail_RequestTopFlex}>
                  <img
                    className={styles.FeeDetail_RequestImg}
                    src={data?.merit_2?.publicURL || ""}
                    alt=""
                  />
                  <div className={styles.FeeDetail_RequestTopContents}>
                    <DocumentStyle>
                      <p className={styles.FeeDetail_RequestTitle}>
                        リクエスト/IPアドレスに対して定められた単価をベースとして、
                        お見積もり作業を通じて最終的なリクエスト数・料金を決定します。
                      </p>
                    </DocumentStyle>
                  </div>
                </div>
                <div className={styles.FeeDetail_Note}>
                  ※スマートフォンアプリケーション診断の料金に関しては
                  <a href="/assessment/sp_app">サービスページ</a>
                  をご覧ください。
                  <br />
                  ※Web・スマートフォンアプリ以外の診断のお見積もりに関してはご予算に合わせて個別にご提案いたします。まずはご相談ください。
                </div>
              </div>
              <div className={styles.FeeDetail_RequestBackground}>
                <DocumentStyle>
                  <h4>リクエストとは？</h4>
                  <p>
                    ボタンを押してページが変わったり、入力が確定するなどしてサーバーとの通信が発生することをリクエストと言います。私たちは、リクエスト数、つまりサイト内で通信が起こりうる箇所の数を基準にお見積もりしています。お見積もりは無料でさせていただきますが、リクエスト数＝画面遷移数で計算しても概算が可能です。
                  </p>
                </DocumentStyle>
                <ul className={styles.Fee_RequestExampleWrapper}>
                  <li className={styles.Fee_RequestExample}>
                    <FadeInAnimation>
                      <h4 className={styles.Fee_RequestH5}>
                        例1.
                        <span className={styles.Fee_RequestPrimary}>
                          画面遷移する際
                        </span>
                      </h4>
                      <div className={styles.Fee_RequestImageWrapper}>
                        <img
                          className={styles.Fee_RequestImage}
                          src={data.request_1.publicURL}
                          alt=""
                        />
                      </div>
                      <DocumentStyle>
                        <p>
                          基本的に、ページ遷移のたびにリクエストが発生するので大まかなリクエスト数は計算することができます
                        </p>
                      </DocumentStyle>
                    </FadeInAnimation>
                  </li>
                  <li className={styles.Fee_RequestExample}>
                    <FadeInAnimation>
                      <h4 className={styles.Fee_RequestH5}>
                        例2.
                        <span className={styles.Fee_RequestPrimary}>
                          画面遷移はしないが通信結果を表示する際
                        </span>
                      </h4>
                      <div className={styles.Fee_RequestImageWrapper}>
                        <img
                          className={styles.Fee_RequestImage}
                          src={data.request_2.publicURL}
                          alt=""
                        />
                      </div>
                      <DocumentStyle>
                        <p>
                          コメントや検索など、一見ページ遷移が行われていなくてもリクエストが発生するパターンもあります
                        </p>
                      </DocumentStyle>
                    </FadeInAnimation>
                  </li>
                </ul>
              </div>
            </div>
          </SubSection>
        </Common2PaneSectionLayout>
      </div>
    </Section>
  );
};
